const stringHelpers = {

  toCamelCase(str) {
    return str.replace(/^([A-Z])|[\s-_](\w)/g, function(match, p1, p2) {
      if (p2) return p2.toUpperCase()
      return p1.toLowerCase()
    })
  },

  /**
   * Check if a HTML string is empty or contains only white spaces
   * @param str
   * @returns {boolean}
   */
  isEmptyHtml(str) {
    return (str || "").replace(/<\/?[^>]+(>|$)/g, "").trim()  === ""
  },

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  },
}

export default stringHelpers
